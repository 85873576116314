import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import {
  Container,
  Title,
  CardGrid,
  Card,
  CardImageLink,
  CardImage,
  CardBody,
  CardTitle,
  CardDate,
  CardDescription,
  CardLink,
} from 'components/News/_news'

interface Datas {
  node: {
    frontmatter: {
      posts: Post
    }
  }
}

interface Post {
  id: number
  slug: string
  title: string
  date: string
  snippet: string
  description: string
  images: {
    src: any
    alt: string
  }
}

const NewsRelated = ({ slug }: any) => {
  const datas = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/news/" } }
        sort: { fields: frontmatter___posts___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              posts {
                id
                slug
                title
                date
                snippet
                description
                images {
                  src {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
  `)

  const filterDatas = datas.allMarkdownRemark.edges.filter(
    ({ node }: Datas) => node.frontmatter.posts.slug !== slug,
  )

  const blogRelatedDatas = filterDatas.map(({ node }: Datas) => (
    <Card key={node.frontmatter.posts.id}>
      <CardImageLink to={`/post/${node.frontmatter.posts.slug}`}>
        <CardImage
          image={node.frontmatter.posts.images.src.childImageSharp.gatsbyImageData}
          alt={node.frontmatter.posts.images.alt}
        />
      </CardImageLink>
      <CardBody>
        <CardTitle>{node.frontmatter.posts.title}</CardTitle>
        <CardDate>{node.frontmatter.posts.date}</CardDate>
        <CardDescription>{node.frontmatter.posts.snippet}...</CardDescription>
        <CardLink to={`/post/${node.frontmatter.posts.slug}`}>{`Read Post >>`}</CardLink>
      </CardBody>
    </Card>
  ))

  return (
    <Container>
      <Title>Related</Title>
      <CardGrid>{blogRelatedDatas.slice(0, 4)}</CardGrid>
    </Container>
  )
}

export default NewsRelated
