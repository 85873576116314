import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import Theme from 'styles/_theme'
import GlobalStyle from 'styles/_global'
import { Wrapper } from 'styles/_mixins'
import { maxScreen } from 'styles/_breakpoints'

import Seo from 'components/Seo/Seo'
import Layout from 'components/Layout/Layout'
import Pagination from 'components/News/Pagination'
import NewsRelated from 'components/News/NewsRelated'

const Container = styled.div`
  width: 100%;
  height: fit-content;
  margin: 15rem 0 20rem;
`

const Card = styled.div`
  width: 100%;
  height: fit-content;
`

const CardBody = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 7rem 0;
  transition: all 0.3s ease-in;
`

const CardTitle = styled.h2`
  margin-bottom: 0.5rem;
  font-size: clamp(5.5rem, 12vw, 9rem);
`

const CardDate = styled.p`
  margin-bottom: 2rem;
  font-size: 1.3rem;
`

const CardBox = styled.div`
  margin-bottom: 3rem;
`

const CardDescription = styled.div`
  text-align: justify;
  margin-bottom: 0;

  p {
    line-height: 3rem;
    margin-bottom: 2rem;
  }
`

const CardImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 35rem;
  height: 35rem;
  float: left;
  margin: 0 3.5rem 1.5rem 0;
  transition: all 0.3s ease-in;

  @media ${maxScreen.smart} {
    width: 100%;
    height: 35rem;
  }
`

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(
      fileAbsolutePath: { regex: "/news/" }
      frontmatter: { posts: { slug: { eq: $slug } } }
    ) {
      frontmatter {
        posts {
          id
          slug
          title
          date
          description
          images {
            src {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100, formats: [AUTO, WEBP])
              }
            }
            alt
          }
        }
      }
    }
  }
`

const Post = ({ data, pageContext }: any) => {
  const { slug, title, date, description, images } = data.markdownRemark.frontmatter.posts

  return (
    <Theme>
      <GlobalStyle />
      <Seo title={title} description={description} />
      <Layout backgrounds='translateY(0)'>
        <Container>
          <Card>
            <Wrapper>
              <CardBody>
                <CardTitle>{title}</CardTitle>
                <CardDate>{date}</CardDate>

                <CardBox>
                  <CardImage
                    image={images.src.childImageSharp.gatsbyImageData}
                    alt={images.alt}
                    objectFit='cover'
                  />
                  <CardDescription>
                    <ReactMarkdown>{description}</ReactMarkdown>
                  </CardDescription>
                </CardBox>

                <Pagination page={pageContext} />
              </CardBody>

              <NewsRelated slug={slug} />
            </Wrapper>
          </Card>
        </Container>
      </Layout>
    </Theme>
  )
}

export default Post
