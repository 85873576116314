import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Container = styled.section`
  width: 100%;
  height: fit-content;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
`

const Item = styled.li`
  padding: 0;

  & > a {
    text-transform: uppercase;
    font-size: 2.5rem;
    color: ${({ theme: { colors } }) => colors.black};
  }
`

const Span = styled.span`
  color: ${({ theme: { colors } }) => colors.pink};
`

const Pagination = ({ page }: any) => {
  const { prevPost, nextPost } = page

  return (
    <Container>
      <List>
        <Item>
          {prevPost && (
            <Link to={`/post/${prevPost.frontmatter.posts.slug}`}>
              <Span>{`<`}</Span> Prev
            </Link>
          )}
        </Item>
        <Item>
          {nextPost && (
            <Link to={`/post/${nextPost.frontmatter.posts.slug}`}>
              Next <Span>{`>`}</Span>{' '}
            </Link>
          )}
        </Item>
      </List>
    </Container>
  )
}

export default Pagination
